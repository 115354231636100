import React, { useState, useEffect } from 'react'
import { Container } from 'react-bootstrap'
import Col from 'react-bootstrap/Col';
import iconbabaji from '../../assets/img/logo.png'
import Row from 'react-bootstrap/Row';
import './Header.css';
import * as Icon from "react-bootstrap-icons";
// import { useState } from 'react';
import { Link } from 'react-router-dom'
import SidebarData from '../Sidebar/SidebarData'
import SidebarDataOne from '../Sidebar/SidebarDataOne'
import profile from '../../assets/img/logo.png';
import { Button } from 'react-bootstrap';
import axios from "axios";
import { useNavigate } from 'react-router-dom';

import Cookies from 'js-cookie';
import Whatsapp from '../../assets/img/whatsappicon.png'
import referimg from '../../assets/img/refer.jpg'


export default function Header() {
  const handleReload = () => {
    window.scrollTo(0, 0);
  };

  function refreshPage() {
    setTimeout(() => {
      window.location.reload(false);
    }, 500);
    // console.log('page to reload')
  }
  const user_id = localStorage.getItem("userid");
  const [link, linkDta] = useState([]);
  const [whatsappLink, whatsappLinks] = useState([]);
  const navigate = useNavigate()

  const [linkwhatsapp, linkwhatsappDta] = useState([]);

  function refercode() {
    linkwhatsappDta('https://api.whatsapp.com/send?phone=' + whatsapp);
    // alert(linkwhatsappDta)
    linkDta('https://api.a2logicgroup.com?reffercode=' + user_id);

  }
  const devid = localStorage.getItem("dev_id");
  // alert(user_id)
  const [open, setOpen] = useState(false);
  const [userData, setUserData] = useState(null);
  const [whatsapp, setwhatsapp] = useState([]);
  const handleClick = () => {
    open === true ? setOpen(false) : setOpen(true);
  }

  useEffect(() => {
    const user_id = localStorage.getItem("userid");
    if (user_id == null) {
      window.location.href = '/login';
    };
    if (devid == null) {
      window.location.href = '/login';
    }
    loaduser1();
    loadUser();
    refercode();
  }, [])
  const loadUser = async () => {
    try {
      const user_id = localStorage.getItem('userid');
      const devid = localStorage.getItem('dev_id');

      let url = `${process.env.REACT_APP_API_URL}/POM_home.php`;
      const formData = new FormData();
      formData.append('app_id', process.env.REACT_APP_API_ID);
      formData.append('user_id', user_id);
      formData.append('dev_id', devid);

      const response = await axios.post(url, formData);
      const objectRes = response.data;
      const whatsapp = response.data.current_market_details.whatsap;
      console.warn(whatsapp);
      setwhatsapp(whatsapp)
      var whatsappLink = 'https://api.whatsapp.com/send?phone=' + whatsapp;
      document.getElementById('whatsappLink');
      whatsappLinks(whatsappLink)
    } catch (error) {
      console.error('Error loading user data:', error);
    }
  };
  const loaduser1 = async () => {
    const user_id = localStorage.getItem("userid");
    const dev_id = localStorage.getItem("dev_id");
    // alert(dev_id);
    let url = (`${process.env.REACT_APP_API_URL}/POM_get_user_profile.php`);
    // console.warn(url);
    const formData = new FormData();
    formData.append('app_id', process.env.REACT_APP_API_ID);
    formData.append('user_id', user_id);
    formData.append('dev_id', dev_id);
    var config = {
      method: 'POST',
      url: url,
      body: formData,
    };
    axios.post(url, formData, config)
      .then(function (response) {
        const res = JSON.stringify(response.data);
        const res1 = response.data.success;
        // alert(res)
        const objectRes = JSON.parse(res);
        setUserData(objectRes);
        if (res1 == 3) {

          localStorage.clear();
          navigate('/Login');
        }
        const login_token = localStorage.getItem('login_token');
        if(objectRes.login_token != login_token){
          localStorage.clear();
          navigate('/login');
        }
        console.warn(objectRes);
      })
  }





  const logout = (e) => {
    e.preventDefault();
    // localStorage.removeItem(user_id);
    localStorage.clear();
    navigate('/login');
  }
  useEffect(() => {
    const user_id = localStorage.getItem("userid");
    if (user_id == null) {
      window.location.href = '/login';
    };
    if (devid == null) {
      window.location.href = '/login';
    }
    loaduser();
  }, [])

  const loaduser = async () => {
    const user_id = localStorage.getItem("userid");
    const dev_id = localStorage.getItem("dev_id");
    // alert(dev_id);
    let url = (`${process.env.REACT_APP_API_URL}/POM_get_user_credit.php`);
    // console.warn(url);
    const formData = new FormData();
    formData.append('app_id', process.env.REACT_APP_API_ID);
    formData.append('user_id', user_id);
    formData.append('dev_id', dev_id);
    var config = {
      method: 'POST',
      url: url,
      body: formData,
    };
    axios.post(url, formData, config)
      .then(function (response) {
        const res = JSON.stringify(response.data);
        const objectRes = JSON.parse(res);
        // setUsers(objectRes);
        console.warn(objectRes);
      })
  }
  return (
    <>
      <div className='header-top'>
        <Container fluid>
          <Row className='d-flex align-items-center justify-content-between'>
            <Col xs={4} md={3} s={4}>
              <div className="menuicon d-flex justify-content-between" >
                <i class="bi bi-list" onClick={handleClick}></i>
                <i class="bi bi-arrow-clockwise" onClick={refreshPage}></i>

              </div>

            </Col>
            <Col xs={4} md={2} s={4}>
              <img src={iconbabaji} width="40" className='mx-auto d-flex justify-content-end' />
            </Col>
            {userData && userData.is_playstore == 0 ?
              <Col xs={4} md={6} s={4}>
                <div className="d-flex justify-content-between align-items-center">

                  <Link to="/Notification" onClick={handleReload} className='buttonpage'>

                    <i class="bi bi-bell-fill text-white"></i>
                  </Link>

                  <div className='ms-auto'>
                    <a href={whatsappLink} id="whatsappLink" className="whatsapp_log d-block">
                      <img src={Whatsapp} className=" whatsapp_icon" />
                    </a>
                  </div>
                  <div>
                  </div>
                </div>
              </Col>
              :
              <></>
            }
          </Row>
        </Container>
        {/* {Sidebardata.map(value =>
         
         )} */}

      </div>
      <div className={open ? "sidebar is-toggle" : "sidebar"} >
        <div className="profileimage">
          <Button className="closebtn" onClick={handleClick}>
            &times;
          </Button>
          {userData && (
            <div className="d-flex align-items-center justify-content-between">

              <div className="profilephoto">
                <img src={profile} />
              </div>

              <div className="profiledetails">
                <h3>{userData.name}</h3>
                <h4><strong>ID : </strong>{user_id}</h4>
              </div>
            </div>
          )}
        </div>
        {userData && userData.is_playstore == 0 ?
          <ul className="bg-white menulist">
            {SidebarData.map(val =>
              <li>
                {val.title == "Logout" ? (
                  <Link to={val.path} onClick={logout} className={val.ownclass}>
                    {val.icon}
                    {val.title}
                  </Link>
                ) : (
                  <Link to={val.path} onClick={handleClick} className={val.ownclass}>
                    {val.icon}
                    {val.title}
                  </Link>
                )}
              </li>
            )}
          </ul>
          :
          <ul className="bg-white menulist">
            {SidebarDataOne.map(val =>
              <li>
                {val.title == "Logout" ? (
                  <Link to={val.path} onClick={logout} className={val.ownclass}>
                    {val.icon}
                    {val.title}
                  </Link>
                ) : (
                  <Link to={val.path} onClick={handleClick} className={val.ownclass}>
                    {val.icon}
                    {val.title}
                  </Link>
                )}
              </li>
            )}
          </ul>
        }
      </div>
      <div className={`sidebar-overlay ${open == true ? 'active' : ''}`} onClick={handleClick}></div>
    </>
  )
}
