import React, { useState, useEffect } from 'react';
import { Container } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import axios from "axios";
import webmanifest from '../../assets/img/kgf_16_04_24.apk';



export default function ReferCode() {

  const user_id = localStorage.getItem("userid");
  const [link, linkDta] = useState("https://khelogalifaridabad.com");
  const [refcode, setRefcode] = useState();
  
  const handleShare = () => {
    // Placeholder for the referral code
    // const refcode = "YOUR_REFERRAL_CODE";
  
    if (navigator.share) {
      navigator.share({
        title: `अपने दोस्तों को रेफर करें और अपने दोस्तों की प्रत्येक हानि बोली (बुकिंग) पर 5% कमीशन राशि प्राप्त करें रेफरल कोड  का उपयोग करके| ${refcode}`,
        // url: `https://play.google.com/store/apps/details?id=com.kgf  ${refcode}`,
        url: `https://khelogalifaridabad.com/kgf_game.apk ${refcode}`,
        
      })
        .then(() => console.log('Shared successfully'))
        .catch((error) => console.error('Error sharing:', error));
    } else {
      const unsupportedBrowserMessage = `
        Sharing is not supported in this browser.
        To share, please manually copy and paste the following link:
        // https://play.google.com/store/apps/details?id=com.kgf
        https://khelogalifaridabad.com/kgf_game.apk
      `;
      // alert(unsupportedBrowserMessage);
    }
  };
  const loaduser1 = async () => {
    const user_id = localStorage.getItem("userid");
    const dev_id = localStorage.getItem("dev_id");
    // alert(dev_id);
    let url = (`${process.env.REACT_APP_API_URL}/POM_get_user_profile.php`);
    // console.warn(url);
    const formData = new FormData();
    formData.append('app_id', process.env.REACT_APP_API_ID);
    formData.append('user_id', user_id);
    formData.append('dev_id', dev_id);
    var config = {
      method: 'POST',
      url: url,
      body: formData,
    };
    axios.post(url, formData, config)
      .then(function (response) {
        const res = JSON.stringify(response.data);
        // alert(res)
        const ref_code = response.data.refCode;
        console.warn(ref_code+'ppppppppppppppp');
        setRefcode(ref_code);
        const objectRes = JSON.parse(res);
        refercode(ref_code);
        // setUserData(objectRes);
        console.warn(ref_code);
      })
  }
  const [linkwhatsapp, linkwhatsappDta] = useState([]);
  
    function refercode(ref_code) {
      
      // linkwhatsappDta('https://play.google.com/store/apps/details?id=com.kgf');
       linkwhatsappDta(`//api.whatsapp.com/send?text=अपने दोस्तों को रेफर करें और अपने दोस्तों की प्रत्येक हानि बोली (बुकिंग) पर 5% कमीशन राशि प्राप्त करें रेफरल कोड का उपयोग करके | रेफरल कोड ${ref_code} https://khelogalifaridabad.com/kgfkadmin/public/kgf.apk`);
      // linkDta('https://khelogalifaridabad.com/Register?reffercode=' + ref_code);
  }
  // const handleShareClick = (ref_code) => {
  //   alert(`अपने दोस्तों को रेफर करें और अपने दोस्तों की प्रत्येक हानि बोली (बुकिंग) पर 5% कमीशन राशि प्राप्त करें रेफरल कोड का उपयोग करके | रेफरल कोड ${refcode}`);
  // };
  useEffect(() => {
    
    loaduser1();
    // console.warn(refcode+'pppp');
    // refercode();
  }, [])
  const copyToClipBoard = async copyMe => {
    // const downloadApk = () => {
      // Use the imported APK path to download
      window.open(webmanifest, '_blank');
    // };
    // try {
      // alert(copyMe);
      await navigator.clipboard.writeText(copyMe);
      // alert(copyMe);
      
  };
  return (
    <>

      <section className='container refer'>
        <div className='margin-bottom-88 mb-0'>
          <div className="pt-1">
            <h3 className='text-dark text-center mt-3'>Refer & Earn</h3>
            <p className='text-secondary'>अगर आप KGF App को अपने friend को डाउनलोड करवाते हो तो आपको 5% कमीशन कमा सकते हो | ये 5% कमीशन आपको लाइफ टाइम मिलेगा |</p>
            <p className='px-2 mt-3'><span className='text-color-danger'>नोट :</span> आपको 5% कमीशन तभी मिलेगा अगर आपका कोई friend गेम खेलता है । और उस गेम से कंपनी को जो Profit हुआ उसका 5% आपको मिलेगा |</p>
            <div className='d-flex justify-content-between'>
              <Link to={linkwhatsapp}  className='d-block btnbal w-100 btn btn-transparent border border-danger mx-1'>
                <i class="bi bi-share-fill sharebtn color_result"></i>
                Share & Earn
              </Link>
              <Link to='' className='d-block btnbal w-100 btn btn-transparent border border-danger mx-1' onClick={(e) => copyToClipBoard(link)} >
                <i class="bi bi-copy me-2 sharebtn color_result" ></i>
             Copy Link & download apk</Link>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
