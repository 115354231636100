import React, { useState, useRef, useEffect } from 'react';
import { Button } from 'react-bootstrap';
import axios from "axios";
import { toast } from 'react-toastify';
import loading from '../../assets/img/loading-gif.gif';
import filesearch from '../../assets/img/filesearch.png';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Toast } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

export default function Crossing() {
  const [areCombinationsGenerated, setAreCombinationsGenerated] = useState(false);
  const [isButtonVisible, setIsButtonVisible] = useState(true);
  const navigate = useNavigate();
  const [users, setUsers] = useState([]);
  const [nums, setNums] = useState('');
  const [st, setSt] = useState([]);
  const [points, setPoints] = useState();
  const [totalPoints, setTotalPoints] = useState();
  const [MaxbetAmt, setMaxbet] = useState([]);
  const [setminibet, setMinibet] = useState([]);
  const [Maxpoints, setMaxpoints] = useState([]);
  const url = new URL(window.location.href);
  const gameid = url.searchParams.get('id');
  const url1 = new URL(window.location.href);
  const name = url1.searchParams.get('name');
  const isButtonLoading = useRef(false);
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  console.warn("crronig",isButtonDisabled)

  const [appmanagerdata, setAppmanager] = useState(null);
  const [minredeem, setMinredeem] = useState(null);
  const [showSubmitButton, setShowSubmitButton] = useState(true);
  const [loading2, setLoading2] = useState(false);
  const [banned, setbanned] = useState([]);
  const [showTemporaryButton, setShowTemporaryButton] = useState(false);
  const [pid, setpid] = useState([]);

  // console.warn(banned)
  // const generateCombinations = () => {
  // if (!nums) {

  //   toast.error('Please enter number ',2000);
  //   return;
  // }
  // if (!points){

  //   toast.error('Please enter points ',2000);
  //   return;
  // }
  //   const numArray = Array.from(new Set(nums.split('').map(Number)));
  //   const n = numArray.length;
  //   let combinations = [];

  //   for (let i = 0; i < n; i++) {
  //     for (let j = 0; j < n; j++) {
  //       combinations.push([numArray[i], numArray[j]]);
  //     }
  //   }


  //   setCombinations(combinations);
  //   setAreCombinationsGenerated(true);
  // };
  // function handleChange(evt) {
  //   setNums(evt.target.value);
  // }

  const [s1, setS1] = useState('');
  const [s2, setS2] = useState('');
  const [result, setResult] = useState(new Set());
  useEffect(() => {
    setS2(s1);
    setResult('')
  }, [s1]);
  const handleCalculate = () => {
    setResult('')
    if (!s1 || !s2) {

      toast.error('Please enter number ', 2000);
      return;
    }
    if (!points) {

      toast.error('Please enter points ', 2000);
      return;
    }
    const st = new Set();
    console.warn(st)
    for (let i = 0; i < s1.length; i++) {
      for (let j = 0; j < s2.length; j++) {
        const tmp1 = s1[i] + s2[j];
        // const tmp2 = s2[j] + s1[i];
        st.add(tmp1);
        // st.add(tmp2);
      }
    }
    // for (let i = 0; i < s1.length; i++) {
    //   for (let j = 0; j < s2.length; j++) {
    //     const tmp1 = s1[i] + s2[j];
    //     const tmp2 = s2[j] + s1[i];
    //     // st.add(tmp1);
    //     // st.add(tmp2);
    //     if (!st.has(tmp2)) {
    //       st.add(tmp1);
    //     }
    //     if (!st.has(tmp1)) {
    //       st.add(tmp2);
    //     }
    //   }
    // }

    setResult(Array.from(st));
    setAreCombinationsGenerated(true);
  };

  const combinationsCount = result.length;
  var totalpoint = combinationsCount * points
  //  console.warn(totalpoint);
  const deleteCombination = (index) => {
    const updatedCombinations = [...result];
    updatedCombinations.splice(index, 1);
    setResult(updatedCombinations);

    const updatedPoints = updatedCombinations.length > 0 ? points : 0;
    const newTotalPoints = updatedCombinations.length * updatedPoints;
    setTotalPoints(newTotalPoints);
    toast.success('Combination deleted successfully! ');
  };

  useEffect(() => {
    loaduser();
    app_manager();
    loaduser1();
    loadUserpid();

  }, [])
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);


  const loadUserpid = async () => {
    try {
      const user_id = localStorage.getItem('userid');
      const devid = localStorage.getItem('dev_id');

      let url = `${process.env.REACT_APP_API_URL}/POM_home.php`;
      const formData = new FormData();
      formData.append('app_id', process.env.REACT_APP_API_ID);
      formData.append('user_id', user_id);
      formData.append('dev_id', devid);

      const response = await axios.post(url, formData);
      const objectRes = response.data;
      const dddddd = response.data.current_date;
      const pid = response.data.pid;
      setpid(pid);



    
    } catch (error) {
      console.error('Error loading user data:', error);
    }
  };
  const loaduser1 = async () => {
    const user_id = localStorage.getItem("userid");
    const dev_id = localStorage.getItem("dev_id");
    // alert(dev_id);
    let url = (`${process.env.REACT_APP_API_URL}/POM_get_user_profile.php`);
    // console.warn(url);
    const formData = new FormData();
    formData.append('app_id', process.env.REACT_APP_API_ID);
    formData.append('user_id', user_id);
    formData.append('dev_id', dev_id);
    var config = {
      method: 'POST',
      url: url,
      body: formData,
    };
    axios.post(url, formData, config)
      .then(function (response) {
        const res = JSON.stringify(response.data);

        const banned = response.data.banned;
        setbanned(banned)

        // alert(res)
        const objectRes = JSON.parse(res);
      })
  }
  const app_manager = async () => {
    const user_id = localStorage.getItem("userid");
    const dev_id = localStorage.getItem("dev_id");

    let url = (`${process.env.REACT_APP_API_URL}/POM_app_manager.php`);
    const formData = new FormData();
    formData.append('app_id', process.env.REACT_APP_API_ID);
    formData.append('user_id', user_id);
    // formData.append('market_id', gameid);
    formData.append('dev_id', dev_id);
    var config = {
      method: 'POST',
      url: url,
      body: formData,
    };
    axios.post(url, formData, config)
      .then(function (response) {
        var setpoints = response.data.points;
        setMaxpoints(setpoints)

        // console.warn(min_redeem)
        // setDeposit(mindeposit);
        const res = JSON.stringify(response.data.data);
        const objectRes = JSON.parse(res);
        setAppmanager(objectRes);
        var setmininumbet = objectRes.crossingMin;
        console.warn(setmininumbet);
        setMinibet(setmininumbet)
        var setmax_betnumbet = objectRes.crossingMax;
        // console.warn(setmax_betnumbet)
        setMaxbet(setmax_betnumbet)
        // console.warn(setmax_betnumbet);

      })

  }
  const loaduser = async () => {
    // setLoading(true);
    const user_id = localStorage.getItem("userid");
    const dev_id = localStorage.getItem("dev_id");
    // alert(dev_id);
    try {
      let url = (`${process.env.REACT_APP_API_URL}/POM_num_tbl.php`);
      // console.warn(url);
      const formData = new FormData();
      formData.append('app_id', process.env.REACT_APP_API_ID);
      formData.append('user_id', user_id);
      formData.append('market_id', gameid);
      formData.append('dev_id', dev_id);
      var config = {
        method: 'POST',
        url: url,
        body: formData,
      };
      axios.post(url, formData, config)
        .then(function (response) {
          var setmininumbet = response.data.mini_bet;
          // var setmax_betnumbet = response.data.max_bet;
          var setpoints = response.data.points;
          setMinibet(setmininumbet)
          // setMaxbet(setmax_betnumbet)
          setMaxpoints(setpoints)
          console.warn(setpoints)
          const res = JSON.stringify(response.data);
          const objectRes = JSON.parse(res);
          setUsers(objectRes);
          console.warn(objectRes.points);
        })
        .finally(() => {
          // setLoading(false);
        });
    } catch (error) {
      console.error('There was a problem with the fetch operation:', error);
      //   setLoading(false);
    }
  }

  const handlePlaceBet = () => {
    // setIsButtonDisabled(true)
    setIsButtonDisabled(true);
    setTimeout(() => {

      setIsButtonDisabled(false);
      }, 5000); // 2-second delay

    loaduser2 ();
    if (banned == '0') {
      // playGameharruf(); 
      setTimeout(() => {
        playgamecrossing();
      }, 1000); // 2-second delay 
    } else if (banned == '1') {
      setLoading2(true);
      setTimeout(() => {
        setLoading2(false);
      }, 20000);
    }
  };


  const playgamecrossing = async () => {
    const user_id = localStorage.getItem("userid");
    const dev_id = localStorage.getItem("dev_id");

    setIsButtonDisabled(true)
    setShowTemporaryButton(true);

    setTimeout(() => {
      setShowTemporaryButton(false);
      // setIsButtonDisabled(true); 
    }, 4000);

    if (banned === '1') {
      // alert('lll')
      setLoading2(true);
      setTimeout(() => {
        setLoading2(false);
      }, 2000);
      return;
    }

    if (parseInt(setminibet) > parseInt(points)) {
      toast.error(`Minimum Bet Placed ${appmanagerdata.crossingMin}`);
      return;
    }
    if (parseInt(MaxbetAmt) < parseInt(points)) {
      console.warn(MaxbetAmt);
      toast.error(`Maximum Bet Placed ${appmanagerdata.crossingMax}`);
      return;
    }
    if (users.points < totalpoint) {
      toast.error(`You Dont Have Sufficient Balance`);
      return;
    }

    setIsButtonVisible(false);
    setIsButtonVisible(false);
    if (!isButtonLoading.current) {
      isButtonLoading.current = true;

      setIsButtonDisabled(true);
      setTimeout(() => {
      // setIsButtonDisabled(false);
      isButtonLoading.current = false;
      }, 3000);
    }
    try {
      const betList = result.map((item, index) => {
        return {
          betkey: item,
          betamount: points,
          session_name: "open",
          bettype: '8',
        };
      }).filter(item => item.betamount > 0);
      setResult('')
      // setLoading2(true);
      // setLoading2(false);
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/play_game.php`, {
        user_id: user_id,
        dev_id: dev_id,
        pid: pid,
        market_id: gameid,
        app_id: process.env.REACT_APP_API_ID,
        BetList: betList,
        dev_model: "web",
        devName: "sumsang"
      });

      setIsButtonVisible(true);
      if (response.data.success == 1) {
        setTimeout(() => {
          // setLoading2(false);

        }, 1000);
        setShowSubmitButton(false);

        // console.warn(response.data.success)
        const MySwal = withReactContent(Swal)
        MySwal.fire({
          title: response.data.message,
          icon: 'success',
          timer: 2500
        })
          .then((result) => {
            navigate('/');
          })
      } else {
        const MySwal = withReactContent(Swal)
        MySwal.fire({
          title: response.data.message,
          timer: 2000,
          icon: 'error'
        });
        return;
      }

      // return;
    } catch (error) {
      console.error('Game store:', error);
      toast.error('An error occurred while Game store. Please try again later.');
    }
    // setLoading2(true);
  };

  var combinationsCounts = combinationsCount * points || 0
  var combinationsCounts1 = combinationsCounts || 0
  //combination with two different input value


  const loaduser2 = async () => {
    const user_id = localStorage.getItem("userid");
    const dev_id = localStorage.getItem("dev_id");
    // alert(dev_id);
    let url = (`${process.env.REACT_APP_API_URL}/POM_get_user_profile.php`);
    // console.warn(url);
    const formData = new FormData();
    formData.append('app_id', process.env.REACT_APP_API_ID);
    formData.append('user_id', user_id);
    formData.append('dev_id', dev_id);
    var config = {
      method: 'POST',
      url: url,
      body: formData,
    };
    axios.post(url, formData, config)
      .then(function (response) {
        const res = JSON.stringify(response.data);
        const refcode = response.data.refCode;
        // setRefcode(refcode)
        // alert(res)
        const objectRes = JSON.parse(res);
        const login_token = localStorage.getItem('login_token');
        
        // setUserData(objectRes);
        console.warn(objectRes.login_token);
        if(objectRes.login_token != login_token){
          localStorage.clear();
          navigate('/login');
        }
      })
  }


  return (
    <>
      <section id="crossing">

        <div className="d-flex justify-content-between px-3">
          <div className="points">
            <h5>Points Remaining : {Math.max(0, users.points - combinationsCounts)}</h5>
          </div>
          {/* <div className="points">
            <h5>Points Added</h5>
            <p>{combinationsCounts}</p>

          </div> */}
        </div>
        <div className="bg-white">
          <div className="d-flex justify-conten-between formnumber">
            <div className="form-group">
              <label>Number</label>
              <input type='text'
                inputMode='numeric' className='form-control' placeholder="Number" value={s1}
                onPaste={(e) => {
                  e.preventDefault();
                  const pastedText = e.clipboardData.getData('text/plain').replace(/[^0-9]/g, '').slice(0, 2);
                  document.execCommand('insertText', false, pastedText);
                }}
                onChange={(e) => {
                  const inputValue = e.target.value.replace(/[^0-9]/g, '').slice(0, 10);
                  setS1(inputValue)
                }}
                required />             </div>
            &nbsp;
            <div className="form-group">
              <label>Number</label>
              <input type='text'
                inputMode='numeric' className='form-control' placeholder="Number" value={s2}
                onPaste={(e) => {
                  e.preventDefault();
                  const pastedText = e.clipboardData.getData('text/plain').replace(/[^0-9]/g, '').slice(0, 2);
                  document.execCommand('insertText', false, pastedText);
                }}
                onChange={(e) => {
                  const inputValue = e.target.value.replace(/[^0-9]/g, '').slice(0, 10);
                  setS2(inputValue)
                }} required />
            </div>
          </div>
          <div className="form-group">
            <label>Points</label>
            <input type='text'
              inputMode='numeric' className='form-control' placeholder="Points"
              value={points}
              onPaste={(e) => {
                e.preventDefault();
                const pastedText = e.clipboardData.getData('text/plain').replace(/[^0-9]/g, '').slice(0, 2);
                document.execCommand('insertText', false, pastedText);
              }}
              onChange={(e) => {

                const inputValue = e.target.value.replace(/[^0-9]/g, '');
                setPoints((inputValue))
              }} required />
          </div>



          <div className="form-btn">
            <button className="btn-add w-100" onClick={handleCalculate}>Add</button>
          </div>

        </div>
        <table className='table mb-5'>
          <thead>
            <tr>
              <td>Number Type </td>
              <td>Number</td>
              <td>Points</td>
            </tr>
          </thead>
          <tbody>
            {Array.from(result).map((item, index) => (
              <tr key={index}>
                <td>Crossing</td>
                <td>{item}</td>
                <td>{points}</td>
                <td className='text-danger' onClick={() => deleteCombination(index)}>
                  <i className="bi bi-trash3"></i>
                </td>
              </tr>
            ))}
          </tbody>
        </table>

        <div className='totalpoints'>
          <div className="d-flex justify-content-between">
            <p>₹</p>
            <p className='ms-1'>{combinationsCounts1}</p>
          </div>

          {/* {showSubmitButton && <button className='btn-add' onClick={playgamecrossing} disabled={!areCombinationsGenerated || isButtonDisabled}>
            <span >Place betsfsd</span>
          </button>} */}

          {showTemporaryButton ? (
            <button className='btn-add' disabled={!areCombinationsGenerated || isButtonDisabled}>
              <span>Place bet</span>
              {/* <span>Place betsfsd</span> */}
            </button>
          ) : (
            showSubmitButton && (
              <button className='btn-add' onClick={handlePlaceBet} disabled={!areCombinationsGenerated || isButtonDisabled}>
                <span>Place bet </span>
              </button>
            )
          )}

        </div>
        <ToastContainer />

      </section>
      {loading2 && (
        <div className="spinner-wrapper">
          <div className="loadernew2"></div>
        </div>
      )}
    </>
  )
}
